export const SHOW_LOADER =                  0x01
export const FETCH_POSTS =                  0x02
export const FETCH_CATEGORY =               0x03
export const FETCH_CATEGORIES =             0x04
export const CHANGE_PAGE =                  0x05
export const CHANGE_POSTS_SORT =            0x06
export const CHANGE_POSTS_FILTER =          0x07
export const FETCH_POST =                   0x08
export const FETCH_USER =                   0x09
export const FETCH_POST_COMMENTS =          0x0a
export const FETCH_USERS =                  0x0b
export const LOGIN =                        0x0c
export const FETCH_ME =                     0x0d
export const FETCH_POST_ACTIONS =           0x0e
export const REGISTER =                     0x0f
export const FETCH_USER_COMMENTS =          0x10
export const FETCH_USER_LIKES =             0x11
export const FETCH_FAVORITES =              0x12